import { useLayoutEffect, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import fade from "../../../styles/transitions/fade.module.css";
import styles from "./List.module.css";

interface Props {
  height: number;
  darkMode: boolean;
  selected: string | undefined; // verse in brose mode
  children: React.ReactNode;
  errorMessage?: string | undefined;
  transitionKey?: string | undefined;
}

const List = ({
  darkMode,
  children,
  selected,
  height,
  errorMessage,
  transitionKey,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const errorClass = `${styles.error} ${darkMode && styles.errorDarkMode}`;

  const style = {
    height,
  };

  useLayoutEffect(() => {
    const element = document.getElementById(`row-${selected}`);

    if (element !== null && ref.current !== null) {
      const offset = element.offsetTop - 200;

      ref.current.scroll({ top: offset, behavior: "smooth" });

      // alternative way of scrolling to selected item
      // element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition classNames={fade} key={transitionKey} timeout={300}>
        <div
          key={transitionKey}
          ref={ref}
          className={styles.container}
          style={style}
        >
          {errorMessage !== undefined ? (
            <div className={errorClass}>{errorMessage}</div>
          ) : (
            children
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default List;
