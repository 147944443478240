import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useURLManipulator from "../../../hooks/useURLManipulator";
import LoadingIndicator from "../../LoadingIndicator";
import NewVerseUI from "./NewVerseUI";
import { NewVerse } from "../../../utils/api/getNewTextVersion";

interface Props {
  newVerses: NewVerse[];
  isLoading?: boolean; // Added to handle explicit loading state
}

const BrowseItems = ({ newVerses, isLoading = false }: Props): JSX.Element => {
  const { verse, chapter } = useSelector((state: RootState) => state.params);
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { updateChapterVerse } = useURLManipulator();

  function extractVerse(reference: string): string {
    const match = reference.match(
      /(?:\d*\s?[A-Za-z]+(?:\s[A-Za-z]+)*)\s+\d{1,3}:(\d{1,3})/
    );
    return match ? match[1] : "";
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const hasValidVerses = newVerses.some(
    (v) => v && v.text && v.text.length > 0 && v.reference
  );

  if (!hasValidVerses) {
    return (
      <div style={{ color: "white" }}>
        No text content is available for this book/chapter.
      </div>
    );
  }

  return (
    <>
      {newVerses.map((newVerse) => {
        if (!newVerse || !newVerse.text || !newVerse.reference) {
          return null;
        }

        const verseNumber = extractVerse(newVerse.reference);
        const isSelected =
          verse !== undefined && verseNumber === verse.toString();

        const onClick = () => {
          if (chapter !== undefined && verseNumber) {
            updateChapterVerse(chapter, verseNumber);
          }
        };

        return (
          <NewVerseUI
            id={`row-${newVerse.reference}`}
            key={newVerse.reference}
            verseNumber={verseNumber}
            text={newVerse.text}
            darkMode={darkMode}
            isSelected={isSelected}
            select={onClick}
          />
        );
      })}
    </>
  );
};

export default BrowseItems;
