import { CSSProperties, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../Overlay";
import { RootState } from "../../../store";
import { setShowVersionPickerModal } from "../../../store/interface";
import styles from "./styles.module.css";
import VersionsList from "./VersionsList";
import useURLManipulator from "../../../hooks/useURLManipulator";
import { versionPickerModalStyle } from "../../../store/screen/panelSelectors";
import VersionPicker from "../VersionPicker";

const VersionPickerModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { toggleTextVersion } = useURLManipulator();

  const modalStyle = useSelector(versionPickerModalStyle);
  const { versionPickerWidth } = useSelector(
    (state: RootState) => state.textPanelContent
  );
  const { darkMode } = useSelector((state: RootState) => state.settings);
  const { showVersionPickerModal: show } = useSelector(
    (state: RootState) => state.interface
  );

  const [_filterText, _setFilterText] = useState("");

  const closeSelf = () => {
    dispatch(setShowVersionPickerModal(false));
  };

  const selectVersion = (version: string) => {
    toggleTextVersion(version); // This will trigger useLoadTextVersion via params change
    closeSelf();
  };

  const modalContainer = `${styles.modalContainer} ${
    darkMode && styles.modalContainerDarkMode
  }`;

  return (
    <Overlay show={show} showHeader={false} closeSelf={closeSelf}>
      {(ref) => (
        <div
          ref={ref}
          style={modalStyle as CSSProperties}
          className={modalContainer}
        >
          <VersionPicker showBorder={false} width={versionPickerWidth} />
          <VersionsList darkMode={darkMode} selectVersion={selectVersion} />
        </div>
      )}
    </Overlay>
  );
};

export default VersionPickerModal;
