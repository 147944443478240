import { useQuery } from "react-query";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { captureMessage } from "@sentry/browser";
import { RootState } from "../store";
import {
  fetchBrowseVersesStart,
  fetchBrowseVersesSuccess,
  fetchBrowseVersesFailure,
} from "../store/textPanelContent";
import { newSelectedVersionCode } from "../store/textPanelContent/selectors";
import getNewTextVersion from "../utils/api/getNewTextVersion";

const useLoadTextVersion = (): void => {
  const dispatch = useDispatch();
  const { bookOrSlug, chapter } = useSelector(
    (state: RootState) => state.params
  );

  const newVersionCode = useSelector(newSelectedVersionCode);

  // Memoize params to prevent recomputation on every render
  const params = useMemo(
    () =>
      newVersionCode !== undefined &&
      bookOrSlug !== undefined &&
      chapter !== undefined
        ? [newVersionCode, bookOrSlug, chapter]
        : [null, null, null],
    [newVersionCode, bookOrSlug, chapter]
  );

  const {
    data: newData,
    error: newError,
    isLoading: isFetching,
  } = useQuery(
    ["newTextVersion", ...params],
    // Use a type guard to ensure params are strings
    () => {
      if (!params[0] || !params[1] || !params[2]) {
        throw new Error("Invalid parameters for getNewTextVersion");
      }
      return getNewTextVersion(params[0], params[1], params[2]);
    },
    {
      enabled: !!params[0] && !!params[1] && !!params[2], // Only run if params are valid
      onError: (err) => {
        captureMessage(
          `Error fetching text content for ${newVersionCode} - ${bookOrSlug} ${chapter}: ${JSON.stringify(
            err
          )}`
        );
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0,
    }
  );

  useEffect(() => {
    if (params[0] && params[1] && params[2]) {
      if (isFetching) {
        dispatch(fetchBrowseVersesStart());
      } else if (newError) {
        dispatch(
          fetchBrowseVersesFailure(
            "No text content is available for this book/chapter."
          )
        );
      } else if (newData !== undefined) {
        if (newData.length === 0) {
          dispatch(
            fetchBrowseVersesFailure(
              "No text content is available for this book/chapter."
            )
          );
        } else {
          dispatch(fetchBrowseVersesSuccess(newData));
        }
      }
    }
  }, [dispatch, newData, newError, isFetching, params]);
};

export default useLoadTextVersion;
