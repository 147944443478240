import { useSelector } from "react-redux";
import Switch from "react-switch";
import { RootState } from "../../store";

import { ReactComponent as On } from "../../assets/icons/toggle-text.svg";
import { ReactComponent as Off } from "../../assets/icons/toggle-no-text.svg";

import colors from "../../styles/colors.module.css";

import styles from "./styles.module.css";
import textPanelStyles from "./TextPanel.module.css";
import useURLManipulator from "../../hooks/useURLManipulator";
import { getPrevTextVersion } from "../../utils/localStorage";

const TextPanelSwitch = (): JSX.Element => {
  const { toggleTextVersion } = useURLManipulator();

  const { textVersion } = useSelector((state: RootState) => state.params);

  const show = textVersion !== undefined;

  const { darkMode } = useSelector((state: RootState) => state.settings);

  const toggle = () => {
    if (textVersion === undefined) {
      // When reopening text, get the version from localStorage
      const prevVersion = getPrevTextVersion();
      if (prevVersion) {
        toggleTextVersion(prevVersion);
      } else {
        // If no previous version in localStorage, use default
        toggleTextVersion("kjv_en");
      }
    } else {
      // When closing text
      toggleTextVersion(undefined);
    }
  };

  const container = `${textPanelStyles.container} ${
    darkMode && textPanelStyles.containerDarkMode
  }`;

  return (
    <div className={container}>
      <Switch
        checked={show}
        onColor={colors.cinnabar}
        offColor={colors.mainSurfaceDark}
        handleDiameter={22.5}
        checkedIcon={
          <div className={styles.iconContainer}>
            <On className={textPanelStyles.onIcon} />
          </div>
        }
        uncheckedIcon={
          <div className={styles.iconContainer}>
            <Off className={textPanelStyles.offIcon} />
          </div>
        }
        offHandleColor={colors.mainSurfaceLight}
        onChange={toggle}
      />
    </div>
  );
};

export default TextPanelSwitch;
