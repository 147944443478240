import { useSelector } from "react-redux";
import styles from "./VersionsList.module.css";
import { RootState } from "../../../store";
import Language from "./Language";
import Version from "./Version";

interface Props {
  // filterText: string;
  darkMode: boolean;
  selectVersion: (versionCode: string) => void;
}

const VersionsList = ({
  darkMode,
  // filterText,
  selectVersion,
}: Props): JSX.Element => {
  // const versions = useSelector(
  //   (state: RootState) => state.textPanelContent.versionsMetadata
  // );

  const languages = useSelector(
    (state: RootState) => state.textPanelContent.newVersionsMetadata
  );

  // TODO: filter by filterText prop
  // const filteredVersions = versions;

  const { textVersion } = useSelector((state: RootState) => state.params);

  const languageContainer = `${styles.languageContainer} ${
    darkMode && styles.languageContainerDarkMode
  }`;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {/* {filteredVersions !== undefined && filteredVersions.length > 0 ? (
          filteredVersions.map((language) => (
            <div key={language.code} className={languageContainer}>
              <Language code={language.code} label={language.label} />
              {language.versions.map((version) => {
                const onClick = () => {
                  selectVersion(version.code);
                };

                return (
                  <Version
                    key={version.code}
                    name={version.label}
                    code={version.code}
                    isSelected={textVersion === version.code}
                    darkMode={darkMode}
                    onClick={onClick}
                  />
                );
              })}
            </div>
          ))
        ) : (
          <div>No versions available</div>
        )} */}
        {languages &&
          languages.length > 0 &&
          languages.map((language) => {
            return (
              <div key={language.languageCode} className={languageContainer}>
                <Language
                  code={language.languageCode}
                  label={language.languageName}
                />
                {language.bibles.map((bible) => {
                  const onClick = () => {
                    selectVersion(bible.code);
                  };

                  return (
                    <Version
                      key={bible.code}
                      name={bible.bible}
                      isSelected={textVersion === bible.code}
                      darkMode={darkMode}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default VersionsList;
