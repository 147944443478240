/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "../../utils/api/getTextVersionsMetadata";
import { NewLanguage } from "../../utils/api/getNewTextVersionsMetadata";
import { Verse } from "../../utils/api/getTextVersion";
import { getPrevTextVersion } from "../../utils/localStorage";
import { SearchResult } from "../../types/searchResult";
import { NewVerse } from "../../utils/api/getNewTextVersion";

export enum Mode {
  browse = "browse",
  search = "search",
}

export enum RequestStatus {
  init = "init",
  failed = "failed",
  loading = "loading",
  succeeded = "succeeded",
}

interface TextPanelContentState {
  rawData?: Verse[];
  newRawData?: NewVerse[];
  error?: string | undefined;
  searchInput: string;
  searchRequestStatus: RequestStatus;
  searchResult: SearchResult | undefined;
  selectedSearchResult: string | undefined;
  mode: Mode;
  versionsMetadata?: Language[];
  newVersionsMetadata?: NewLanguage[];
  previouslySelectedVersion?: string;
  versionPickerWidth: number;
  browseRequestStatus: RequestStatus; // Added for browse mode loading
}

const initialState: TextPanelContentState = {
  mode: Mode.browse,
  previouslySelectedVersion: getPrevTextVersion(),
  versionPickerWidth: 0,
  searchInput: "",
  searchResult: undefined,
  searchRequestStatus: RequestStatus.init,
  selectedSearchResult: undefined,
  browseRequestStatus: RequestStatus.init, // Initialize new field
};

const textPanelContentSlice = createSlice({
  name: "textPanelContent",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<Mode>): void => {
      state.mode = action.payload;
    },
    setSearchInput: (state, action: PayloadAction<string>): void => {
      state.searchInput = action.payload;
    },
    setSearchResult: (
      state,
      action: PayloadAction<SearchResult | undefined>
    ): void => {
      state.searchResult = action.payload;
    },
    setSelectedSearchResult: (
      state,
      action: PayloadAction<string | undefined>
    ): void => {
      state.selectedSearchResult = action.payload;
    },
    setSearchRequestStatus: (
      state,
      action: PayloadAction<RequestStatus>
    ): void => {
      state.searchRequestStatus = action.payload;
    },
    toggleMode: (state): void => {
      state.mode = state.mode === Mode.browse ? Mode.search : Mode.browse;
    },
    setPreviouslySelectedVersion: (
      state,
      action: PayloadAction<string | undefined>
    ): void => {
      state.previouslySelectedVersion = action.payload;
    },
    setTextPanelError: (
      state,
      action: PayloadAction<string | undefined>
    ): void => {
      state.error = action.payload;
    },
    setTextPanelContent: (
      state,
      action: PayloadAction<Verse[] | undefined>
    ): void => {
      state.rawData = action.payload;
    },
    setNewTextPanelContent: (
      state,
      action: PayloadAction<NewVerse[] | undefined>
    ): void => {
      state.newRawData = action.payload;
      state.browseRequestStatus = RequestStatus.succeeded;
    },
    setVersionsMetadata: (
      state,
      action: PayloadAction<Language[] | undefined>
    ): void => {
      state.versionsMetadata = action.payload;
    },
    setNewVersionsMetadata: (
      state,
      action: PayloadAction<NewLanguage[] | undefined>
    ): void => {
      state.newVersionsMetadata = action.payload;
    },
    setVersionPickerWidth: (state, action: PayloadAction<number>): void => {
      state.versionPickerWidth = action.payload;
    },
    // New actions for browse fetch
    fetchBrowseVersesStart: (state): void => {
      state.browseRequestStatus = RequestStatus.loading;
      state.newRawData = undefined;
      state.error = undefined;
    },
    fetchBrowseVersesSuccess: (
      state,
      action: PayloadAction<NewVerse[]>
    ): void => {
      state.browseRequestStatus = RequestStatus.succeeded;
      state.newRawData = action.payload;
      state.error = undefined;
    },
    fetchBrowseVersesFailure: (state, action: PayloadAction<string>): void => {
      state.browseRequestStatus = RequestStatus.failed;
      state.newRawData = undefined;
      state.error = action.payload;
    },
  },
});

export const {
  setMode,
  toggleMode,
  setSearchInput,
  setSearchResult,
  setTextPanelError,
  setTextPanelContent,
  setNewTextPanelContent,
  setVersionsMetadata,
  setNewVersionsMetadata,
  setVersionPickerWidth,
  setSearchRequestStatus,
  setSelectedSearchResult,
  setPreviouslySelectedVersion,
  fetchBrowseVersesStart,
  fetchBrowseVersesSuccess,
  fetchBrowseVersesFailure,
} = textPanelContentSlice.actions;

export default textPanelContentSlice.reducer;
