import axios from "axios";

export interface NewVerse {
  reference: string;
  text: string;
}

const bibleBooks = [
  { code: "1ch", name: "1-chronicles" },
  { code: "1co", name: "1-corinthians" },
  { code: "1jn", name: "1-john" },
  { code: "1ki", name: "1-kings" },
  { code: "1pe", name: "1-peter" },
  { code: "1sa", name: "1-samuel" },
  { code: "1th", name: "1-thessalonians" },
  { code: "1ti", name: "1-timothy" },
  { code: "2ch", name: "2-chronicles" },
  { code: "2co", name: "2-corinthians" },
  { code: "2jn", name: "2-john" },
  { code: "2ki", name: "2-kings" },
  { code: "2pe", name: "2-peter" },
  { code: "2sa", name: "2-samuel" },
  { code: "2th", name: "2-thessalonians" },
  { code: "2ti", name: "2-timothy" },
  { code: "3jn", name: "3-john" },
  { code: "act", name: "acts" },
  { code: "amo", name: "amos" },
  { code: "col", name: "colossians" },
  { code: "dan", name: "daniel" },
  { code: "deu", name: "deuteronomy" },
  { code: "ecc", name: "ecclesiastes" },
  { code: "eph", name: "ephesians" },
  { code: "est", name: "esther" },
  { code: "exo", name: "exodus" },
  { code: "ezk", name: "ezekiel" },
  { code: "ezr", name: "ezra" },
  { code: "gal", name: "galatians" },
  { code: "gen", name: "genesis" },
  { code: "hab", name: "habakkuk" },
  { code: "hag", name: "haggai" },
  { code: "heb", name: "hebrews" },
  { code: "hos", name: "hosea" },
  { code: "isa", name: "isaiah" },
  { code: "jas", name: "james" },
  { code: "jer", name: "jeremiah" },
  { code: "job", name: "job" },
  { code: "jol", name: "joel" },
  { code: "jhn", name: "john" },
  { code: "jon", name: "jonah" },
  { code: "jos", name: "joshua" },
  { code: "jud", name: "jude" },
  { code: "jdg", name: "judges" },
  { code: "lam", name: "lamentations" },
  { code: "lev", name: "leviticus" },
  { code: "luk", name: "luke" },
  { code: "mal", name: "malachi" },
  { code: "mrk", name: "mark" },
  { code: "mat", name: "matthew" },
  { code: "mic", name: "micah" },
  { code: "nam", name: "nahum" },
  { code: "neh", name: "nehemiah" },
  { code: "num", name: "numbers" },
  { code: "oba", name: "obadiah" },
  { code: "phm", name: "philemon" },
  { code: "php", name: "philippians" },
  { code: "pro", name: "proverbs" },
  { code: "psa", name: "psalms" },
  { code: "rev", name: "revelation" },
  { code: "rom", name: "romans" },
  { code: "rut", name: "ruth" },
  { code: "sng", name: "song-of-solomon" },
  { code: "tit", name: "titus" },
  { code: "zec", name: "zechariah" },
  { code: "zep", name: "zephaniah" },
];
function getBookName(code: string): string {
  const book = bibleBooks.find((b) => b.code === code.toLowerCase());
  return book ? book.name : "book code not found";
}

const getNewTextVersion = async (
  bibleCode: string | null,
  book: string | null,
  chapter: string | null
): Promise<NewVerse[] | undefined> => {
  if (bibleCode !== null && book !== null && chapter !== null) {
    const bookName = getBookName(book.toLowerCase());

    const { data } = await axios.get<NewVerse[]>(
      `${process.env.REACT_APP_DEAFBIBLE_API_V2}/${bibleCode}/${bookName}/${chapter}`
    );

    return data;
  }

  return undefined;
};

export default getNewTextVersion;
