import axios from "axios";

export interface NewLanguage {
  languageCode: string;
  languageName: string;
  bibles: NewVersion[];
}

export interface NewVersion {
  bible: string;
  code: string;
}

const getNewTextVersionsMetadata = async (): Promise<NewLanguage[]> => {
  const { data: versions } = await axios.get<NewLanguage[]>(
    `${process.env.REACT_APP_DEAFBIBLE_API_V2}/list`
  );

  return versions;
};

export default getNewTextVersionsMetadata;
