import styles from "./Version.module.css";

interface Props {
  name: string;
  isSelected: boolean;
  darkMode: boolean;
  onClick: () => void;
}

const Version = ({
  name,
  isSelected,
  onClick,
  darkMode,
}: Props): JSX.Element => {
  const container = `${styles.container} ${
    darkMode && styles.containerDarkMode
  } ${isSelected && styles.containerSelected} ${
    isSelected && darkMode && styles.containerSelectedDarkMode
  }`;

  return (
    <div
      role="button"
      onKeyDown={undefined}
      tabIndex={0}
      onClick={onClick}
      className={container}
    >
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default Version;
